import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import * as firebase from 'firebase';

firebase.initializeApp({
  apiKey: "AIzaSyBeyNZoNuLaf9GlfO3u-5tgQFGTROg12Fg",
  authDomain: "prop-management-backend.firebaseapp.com",
  databaseURL: "https://prop-management-backend.firebaseio.com",
  projectId: "prop-management-backend",
  storageBucket: "prop-management-backend.appspot.com",
  messagingSenderId: "561786066294",
  appId: "1:561786066294:web:a9788368be1193cfa29657"
});
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
