import React from 'react';
import { Button, Spinner } from 'reactstrap';

function LoaderButton(props) {
  return (
    <Button
      color={props.color}
      id={props.id}
      className={props.className}
      onClick={props.loading ? null : props.onClick}
      type={props.typef}>
      {props.loading
        ? <Spinner style={{ width: "24px", height: "24px" }} color={props.spinnerColor || "light"} />
        : props.children}
    </Button>
  )
}

export default LoaderButton;