import React, { useState, useEffect, createContext } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Spinner, Toast, ToastBody } from 'reactstrap';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard'
import * as firebase from 'firebase';

export const AppContext = createContext();

function App() {
  const [currentUser, setCurrentUser] = useState("loading");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, []);

  const setError = (error) => {
    setSuccessMsg("")
    setErrorMsg(error);
    setIsToastOpen(true)
  }

  const setSuccess = (error) => {
    setErrorMsg("");
    setSuccessMsg(error);
    setIsToastOpen(true)
  }

  const closeToast = () => {
    setErrorMsg("");
    setSuccessMsg("");
    setIsToastOpen(false);
  }

  const showToast = () => {
    return (
      <Toast className="fixed-bottom mb-4" style={{ margin: "auto" }} isOpen={isToastOpen}>
        <div className="toast-header">
          <strong className="mr-auto">{successMsg ? "All done!" : "Uh oh!"}</strong>
          <button type="button" className="ml-2 mb-1 close" onClick={closeToast}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ToastBody>{successMsg ? successMsg : errorMsg}</ToastBody>
      </Toast>
    );
  }

  if (currentUser === "loading") {
    return (
      <div className="row h-100 w-100 justify-content-center align-items-center">
        <Spinner />
        <p className="ml-2 my-0">Loading</p>
      </div>
    );
  }

  return (
    <AppContext.Provider value={{ setError, setSuccess }}>
      <Router>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="/">
            {!currentUser ? <Redirect to="/login" /> : <Dashboard />}
          </Route>
        </Switch>
      </Router>
      {errorMsg || successMsg ? showToast() : null}
    </AppContext.Provider>
  );
}

export default App;
